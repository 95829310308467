var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"personalization-editor",attrs:{"data-test-id":"personalizationEditor"}},[(_vm.loading)?_c('v-container',{staticClass:"loading-overlay"},[_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"92","color":"primary"}})],1)],1):_c('div',{staticClass:"d-flex flex-column mb-3"},[_c('v-toolbar',{attrs:{"bottom":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Personalization Attributes")]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":"","inset":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticClass:"help-tooltip",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.description))}})])],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Inheritance configuration")])],1),_c('div',{staticClass:"d-flex flex-grow-1 flex-column pl-4"},[_c('PropertiesInheritance',{attrs:{"schema":_vm.schema,"properties":_vm.personalizationsForInheritance,"uimodel":_vm.uimodel,"remove-item-callback":_vm.checkSchemaType}})],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Personalizations")]),_c('v-spacer'),(!_vm.loading)?_c('v-btn',{attrs:{"small":"","color":"primary","data-test-id":"addPersonalizationBtn"},on:{"click":_vm.addPersonalization}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Personalization ")],1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column pl-4"},[(_vm.personalizations.length === 0)?_c('v-subheader',{staticClass:"d-flex justify-center"},[_vm._v("No personalizations found")]):_c('v-treeview',{staticClass:"personalizations-tree pl-4 mt-2",attrs:{"items":_vm.personalizationTree,"selectable":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('div',{staticClass:"d-flex justify-start",attrs:{"data-test-id":'personalization_' + item.id}},[_c('span',{class:{
                'personalization-name': true,
                preset: _vm.isPreset(item.id),
                global: _vm.isGlobalPersonalization(item.id),
                'inherited font-weight-medium':
                  !item.isCategory && _vm.isInherited(item.id),
                'removed font-weight-medium':
                  !item.isCategory && _vm.isRemoved(item.id),
              }},[_vm._v(" "+_vm._s(_vm.getPersonalizationName(item))+" ")])]),(selected && !item.isCategory)?_c('SchemaEditor',{key:_vm.reloadPersonalizations[item.id],attrs:{"uimodel":_vm.models[item.id],"type-editable":_vm.existsOnlyInSchema(item.id),"allowed-types":_vm.allowedSchemaTypes,"parent-type":"schema","hide-name":"","disable-restore":"","allow-schema-range-changes":_vm.schemaRangeChangesAllowed(item.id)},on:{"input":function($event){return _vm.updateSchema(item.id, $event)}}}):_vm._e()]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}}),(_vm.personalizations.length > 0)?_c('div',{staticClass:"d-flex flex-column"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Required Personalizations")]),_c('RequiredPropertiesTooltip')],1),_c('div',{staticClass:"d-flex flex-wrap pl-6"},[_c('RequiredProperties',{attrs:{"schema":_vm.schema,"properties":_vm.personalizations,"uimodel":_vm.uimodel,"inherited-schema":_vm.inheritedSchema,"fixed-required-properties":_vm.requiredPresets}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }