<template>
  <v-container
    class="personalization-editor"
    data-test-id="personalizationEditor"
  >
    <v-container v-if="loading" class="loading-overlay">
      <v-overlay>
        <v-progress-circular indeterminate size="92" color="primary" />
      </v-overlay>
    </v-container>
    <div v-else class="d-flex flex-column mb-3">
      <v-toolbar bottom flat>
        <v-toolbar-title>Personalization Attributes</v-toolbar-title>
        <v-divider class="mx-3" vertical inset />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" right>
              mdi-help-circle-outline
            </v-icon>
          </template>
          <div v-html="$sanitize(description)" class="help-tooltip" />
        </v-tooltip>
      </v-toolbar>
      <v-toolbar flat>
        <v-subheader>Inheritance configuration</v-subheader>
      </v-toolbar>
      <div class="d-flex flex-grow-1 flex-column pl-4">
        <PropertiesInheritance
          :schema="schema"
          :properties="personalizationsForInheritance"
          :uimodel="uimodel"
          :remove-item-callback="checkSchemaType"
        />
      </div>
      <v-toolbar flat>
        <v-subheader>Personalizations</v-subheader>
        <v-spacer />
        <v-btn
          v-if="!loading"
          small
          color="primary"
          data-test-id="addPersonalizationBtn"
          @click="addPersonalization"
        >
          <v-icon left>mdi-plus</v-icon> Add Personalization
        </v-btn>
      </v-toolbar>
      <div class="d-flex flex-column pl-4">
        <v-subheader
          v-if="personalizations.length === 0"
          class="d-flex justify-center"
          >No personalizations found</v-subheader
        >
        <v-treeview
          v-else
          v-model="active"
          :items="personalizationTree"
          selectable
          class="personalizations-tree pl-4 mt-2"
        >
          <template v-slot:label="{ item, selected }">
            <div
              class="d-flex justify-start"
              :data-test-id="'personalization_' + item.id"
            >
              <span
                :class="{
                  'personalization-name': true,
                  preset: isPreset(item.id),
                  global: isGlobalPersonalization(item.id),
                  'inherited font-weight-medium':
                    !item.isCategory && isInherited(item.id),
                  'removed font-weight-medium':
                    !item.isCategory && isRemoved(item.id),
                }"
              >
                {{ getPersonalizationName(item) }}
              </span>
            </div>
            <SchemaEditor
              v-if="selected && !item.isCategory"
              :uimodel="models[item.id]"
              :type-editable="existsOnlyInSchema(item.id)"
              :allowed-types="allowedSchemaTypes"
              :key="reloadPersonalizations[item.id]"
              parent-type="schema"
              hide-name
              disable-restore
              :allow-schema-range-changes="schemaRangeChangesAllowed(item.id)"
              @input="updateSchema(item.id, $event)"
            />
          </template>
        </v-treeview>
        <div v-if="personalizations.length > 0" class="d-flex flex-column">
          <v-toolbar flat>
            <v-subheader>Required Personalizations</v-subheader>
            <RequiredPropertiesTooltip />
          </v-toolbar>
          <div class="d-flex flex-wrap pl-6">
            <RequiredProperties
              :schema="schema"
              :properties="personalizations"
              :uimodel="uimodel"
              :inherited-schema="inheritedSchema"
              :fixed-required-properties="requiredPresets"
            />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import mixin from "mixins/schema-editor-mixin";
import RequiredProperties from "../attributes/properties/RequiredProperties";
import RequiredPropertiesTooltip from "../attributes/properties/RequiredPropertiesTooltip";
import PropertiesInheritance from "../attributes/properties/PropertiesInheritance";

export default {
  inject: {
    getPropertyData: {
      default() {
        return () => {
          return null;
        };
      },
    },

    getProductType: {
      default() {
        return () => {
          return null;
        };
      },
    },
  },

  mixins: [mixin],

  components: {
    SchemaEditor: () => import("../SchemaEditor.vue"),
    RequiredProperties,
    PropertiesInheritance,
    RequiredPropertiesTooltip,
  },

  props: {
    value: {
      type: Object,
      required: false,
    },

    uimodel: {
      type: Object,
      required: true,
    },

    valueSource: {
      type: Object,
      required: false,
    },

    domain: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      schema: this.value ?? this.uimodel.schema,
      models: {},
      personalizationTree: [],
      active: [],
      loading: true,
      inheritedSchema: undefined,
      updatePersonalization: 0,
      inheritanceExcludedProperties: [],
      reloadKey: 0,
      reloadPersonalizations: {},
      canChangePresetPersonAttributes: true,
      presets: null,
      globalPersonalizations: null,
      requiredPresets: [],
    };
  },

  watch: {
    "uimodel.schema": {
      handler: function (schema) {
        if (this.$isEqual(schema, this.schema)) return;
        this.schema = schema;
        //built the uimodels and active personalizations before updating the tree
        //to remove unused personalizations correctly
        this.buildPersonUIModels();
        this.updateActivePersonalizations();
        this.$nextTick(() => this.buildTree());
      },
      deep: true,
    },

    value(value) {
      this.$set(this.uimodel, "schema", value);
    },

    active(active) {
      //remove all personalizations which are not in the active array
      //from the schema
      if (this.schema?.properties) {
        const properties = Object.keys(this.schema.properties);
        properties.forEach((key) => {
          if (active.includes(key)) return;
          this.$delete(this.schema.properties, key);
        });
      }

      //update the active personalizations in the schema
      active.forEach((name) =>
        this.updateSchema(name, this.models?.[name]?.schema)
      );
    },

    "schema.inheritProperties": function () {
      this.rebuildPersonEditor();
    },

    "schema.inheritExcludePropertyInformation": function (current, previous) {
      if (this.$isEqual(current, previous)) return;
      this.rebuildPersonEditor();
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      try {
        //1. Load global list and allowed preset changes configuration
        await Promise.allSettled([
          this.loadAllowedChangesConfig(),
          this.loadGlobalPersonalizations(),
        ]);

        //2. Load the personalization presets for this specific product type
        const productType = this.getProductType();
        const presetKeySpace = "Configuration";
        const presetKeyPattern =
          "bl.personalization.global.preset." + productType;
        let productTypePresets;
        if (productType) {
          const merged =
            await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
              this.selectedDomain,
              presetKeySpace,
              presetKeyPattern
            );

          this.requiredPresets = merged?.schema?.required;
          productTypePresets = merged?.schema?.properties;
          this.sanitizePropertySchemas(productTypePresets);
        }

        //3. Get the value source either from the given params or the merged schema,
        //load the values and add them to the presets
        const valueSource =
          this.valueSource ??
          this.uimodel?.mergedSchema?.schemaUI?.editorParameters?.valueSource;

        let presets;

        const valueSourceEqualsPreset =
          productType &&
          valueSource?.key === presetKeyPattern &&
          valueSource?.keySpace === presetKeySpace;

        if (valueSource && !valueSourceEqualsPreset) {
          const values = await this.getPropertyData(valueSource);
          this.sanitizePropertySchemas(values);

          presets = this.$cloneObject(values);
          if (productTypePresets) {
            if (!presets) presets = {};
            Object.keys(productTypePresets).forEach((property) => {
              if (presets?.[property] === undefined) {
                this.$set(presets, property, productTypePresets[property]);
              }
            });
          }
        } else {
          //valuesource does not exist or equals the preset data location,
          //so just use the product type specific
          presets = this.$cloneObject(productTypePresets);
        }
        this.presets = presets;

        /*
          4. Load the inherited schema by using the config lookup endpoint
          and retrieve the merged schema from the parent of the current key. 
          This is the inherited schema.
        */
        const lookupRes =
          await this.$store.$coreApi.coreConfigurationApi.configLookup(
            this.selectedDomain,
            "SKU",
            this.uimodel.key,
            {
              lookupRegion: ["schema"],
              keyMatching: "matchPattern",
              includeParentDomains: true,
            }
          );

        const parentSchemas = lookupRes ?? [];
        const parentSchemaRule = parentSchemas.reduce((parent, schema) => {
          const isCurrentSchema =
            schema.key === this.uimodel.key &&
            this.selectedDomain === schema?.domain?.id;
          if (!isCurrentSchema && schema.specificity > parent.specificity) {
            return schema;
          }
          return parent;
        });

        if (parentSchemaRule) {
          const inheritedSchema =
            await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
              parentSchemaRule.domain.id,
              parentSchemaRule.keySpace,
              parentSchemaRule.key
            );

          this.inheritedSchema = this.$getObjectValueByPath(
            inheritedSchema?.schema,
            this.personalizationPath
          );

          this.$set(
            this.uimodel,
            "inheritedSchema",
            this.$cloneObject(this.inheritedSchema)
          );
        }

        //5. Build the personalization editor
        this.rebuildPersonEditor();
      } finally {
        this.loading = false;
      }
    },

    async loadAllowedChangesConfig() {
      const res = await this.$store.$coreApi.coreConfigurationApi.getMergedData(
        this.selectedDomain,
        "Configuration",
        "bl.personalization.validation",
        null
      );

      //Default value is true
      this.canChangePresetPersonAttributes =
        res?.data?.changePresetPersonAttributes ?? true;
    },

    async loadGlobalPersonalizations() {
      const merged =
        await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
          this.selectedDomain,
          "Configuration",
          "bl.personalization.global"
        );
      this.globalPersonalizations = merged?.schema?.properties;
    },

    sanitizePropertySchemas(properties) {
      if (properties && this.$isPlainObject(properties)) {
        Object.values(properties).forEach((schema) => {
          //remove title and description values, because these will not be stored in
          //the schema but in the localization service
          if (schema?.title) this.$delete(schema.title, "values");
          if (schema?.description) this.$delete(schema.description, "values");
        });
      }
    },

    async rebuildPersonEditor() {
      //build the ui models
      this.buildPersonUIModels();
      await this.$nextTick();
      //Built personalization tree
      this.buildTree();
      await this.$nextTick();
      //set the active personalizations
      this.updateActivePersonalizations();
    },

    buildTree() {
      //build the personalization tree
      let tree = [];
      const personalizations = this.personalizations;
      personalizations.forEach((key) => {
        //add the personalization to the tree
        if (!this.models[key] || tree.some((leaf) => leaf.id === key)) {
          return;
        }

        const isInherited = this.isInherited(key);
        const category = this.getCategory(key);
        if (category) {
          let categoryNode = tree.find((leaf) => leaf.id === category?.id);
          if (!categoryNode) {
            const id = category.id;
            const name = category.label ?? id;
            categoryNode = {
              id,
              name,
              children: [],
              disabled: false,
              isCategory: true,
            };
            tree.unshift(categoryNode);
          }
          if (categoryNode.children.some((child) => child.id === key)) {
            return;
          }
          categoryNode.children.push({
            id: key,
            name: key,
            disabled: isInherited,
          });
          return;
        } else {
          tree.push({
            id: key,
            name: key,
            disabled: isInherited,
          });
        }
      });

      const getLeafSortNumber = (leaf) => {
        if (leaf.isCategory) {
          if (leaf?.id === "name") return 1;
          if (leaf?.id === "address") return 2;
          return 3;
        }
        return 4;
      };

      //sort the personalizations so that the active ones are above
      //the others.
      tree.sort((leaf1, leaf2) => {
        const number1 = getLeafSortNumber(leaf1);
        const number2 = getLeafSortNumber(leaf2);
        if (number1 !== number2) {
          return number1 - number2;
        }
        return leaf1.id.localeCompare(leaf2.id);
      });

      this.personalizationTree = Object.assign([], tree);
    },

    buildPersonUIModels() {
      this.models = {};
      //build a uimodel for each preset in the personalizations object
      this.personalizations.forEach((name) => {
        const schema = this.getPersonSchema(name);
        //create UI model from the schema
        const uimodel = {
          schema,
          inherited: false,
          savedSchema: this.uimodel?.savedSchema?.properties?.[name],
          domain: this.uimodel.domain,
          key: this.uimodel.key,
          keySpace: this.uimodel.keySpace,
          level: this.uimodel.level + 1,
          path: this.uimodel.path + "/" + name,
          editable: true,
          name,
        };
        this.$set(this.models, name, uimodel);
      });
    },

    getPersonSchema(personalizationId, currentSchema) {
      //Build the personalization schema
      const inheritProperties = this.schema.inheritProperties;
      const excludedProperties =
        this.schema.inheritExcludePropertyInformation ?? [];

      //merge the schema of the preset, the inherited schema and the current schema
      const preset = this.$cloneObject(this.presets?.[personalizationId]);
      const inherited = this.$cloneObject(
        this.inheritedSchema?.properties?.[personalizationId]
      );
      const existing = this.$cloneObject(
        currentSchema ?? this.schema?.properties?.[personalizationId]
      );
      const saved = this.$cloneObject(
        this.uimodel?.savedSchema?.properties?.[personalizationId]
      );
      let schema;

      if (existing?.inherit !== false) {
        //if there is no saved schema merge the presets and inherited schema
        //into the current
        if (preset) {
          schema = this.merge(preset, schema);
        }

        const inheritedTypeDiffers =
          this.isTypeDifferent(existing, inherited) ||
          this.isTypeDifferent(schema, inherited);
        if (
          !inheritedTypeDiffers &&
          inheritProperties &&
          !excludedProperties.includes(personalizationId)
        ) {
          schema = this.merge(inherited, schema);
        }
      }

      const savedTypeDiffers =
        this.isTypeDifferent(existing, saved) ||
        this.isTypeDifferent(schema, saved);
      if (existing?.type && !savedTypeDiffers) {
        //if there is save schema and it has the same type, use it as base
        schema = this.merge(saved, schema);
      }
      return this.merge(existing, schema);
    },

    updateActivePersonalizations() {
      //get the active personalizations from both inherited and current schemas
      //also build the personalization tree in the process
      let activePersonalizations = [];
      const inheritedProperties = this.inheritedSchema?.properties;

      if (inheritedProperties) {
        const keys = Object.keys(inheritedProperties);
        keys.forEach((key) => {
          if (this.isInherited(key)) {
            activePersonalizations.push(key);
          }
        });
      }

      const properties = this.schema?.properties;
      if (properties) {
        const keys = Object.keys(properties);
        keys.forEach((key) => {
          if (!activePersonalizations.includes(key))
            activePersonalizations.push(key);
        });
      }

      this.active = activePersonalizations;
    },

    updateSchema(personalizationId, schema) {
      //update the given property of the schema
      if (!this.schema?.properties) this.$set(this.schema, "properties", {});
      let diffSchema = this.$cloneObject(schema);
      const savedPerson =
        this.uimodel?.savedSchema?.properties?.[personalizationId];

      //check if there is already an inherited schema
      //if no, just set the given schema
      const inheritedPerson =
        this.inheritedSchema?.properties?.[personalizationId];
      if (!inheritedPerson) {
        this.$set(this.schema.properties, personalizationId, diffSchema);
        this.$emit("input", this.schema);
        return;
      }

      if (this.isInherited(personalizationId)) {
        const preset = this.presets?.[personalizationId];
        if (preset) {
          if (preset?.title?.i18nKey === schema?.title?.i18nKey) {
            //Remove the title if the i18nKey are equal
            this.$delete(diffSchema, "title");
          }
          this.extractSchemaDifference(diffSchema, schema, preset, savedPerson);
        }

        const typeDiffers = this.isTypeDifferent(schema, inheritedPerson);
        if (typeDiffers) {
          //if the property is inherited and the type is different,
          //remove the current property from the schema and
          //replace it by the inherited one
          this.$delete(this.schema.properties, personalizationId);
          if (Object.keys(this.schema.properties).length === 0) {
            this.$delete(this.schema, "properties");
          }

          this.$nextTick(() => {
            if (this.models?.[personalizationId]) {
              const updatedSchema = this.getPersonSchema(
                personalizationId,
                inheritedPerson
              );
              this.$set(
                this.models[personalizationId],
                "schema",
                updatedSchema
              );
              //Re-mount the schema editor compennt so that it
              //refreshes the currently displayed data
              const reloadKey =
                !this.reloadPersonalizations?.[personalizationId];
              this.$set(
                this.reloadPersonalizations,
                personalizationId,
                reloadKey
              );
            }
          });

          this.$emit("input", this.schema);
          return;
        } else {
          this.extractSchemaDifference(
            diffSchema,
            schema,
            inheritedPerson,
            savedPerson
          );
        }
      }

      if (!diffSchema || Object.keys(diffSchema).length === 0) {
        //if there is no difference and either no saved schema or
        //the saved schema is not different to the inherited schema,
        //remove the property from the current schema
        this.$delete(this.schema.properties, personalizationId);
        if (Object.keys(this.schema.properties).length === 0) {
          this.$delete(this.schema, "properties");
        }
      } else {
        //set type to avoid merge conflicts
        this.$set(diffSchema, "type", schema.type);
        this.$set(diffSchema, "inherit", schema?.inherit);
        this.$set(this.schema.properties, personalizationId, diffSchema);
      }

      this.$nextTick(() => {
        //Update the schema of the personalization model, so that the
        //schema editor recognizes the changes immediately
        if (this.models?.[personalizationId]) {
          const updatedSchema = this.getPersonSchema(
            personalizationId,
            diffSchema
          );
          this.$set(this.models[personalizationId], "schema", updatedSchema);
        }
      });
      this.$emit("input", this.schema);
    },

    extractSchemaDifference(diffSchema, schema, inherited, saved) {
      const inheritanceDisabled = schema?.inherit === false;
      const inheritAttributeChanged =
        saved?.inherit === false && schema?.inherit === true;

      //extract the differences between given schema and inherit schema
      //and update the diffSchema
      Object.keys(inherited).forEach((property) => {
        const savedValue = saved?.[property];
        const inheritedValue = inherited?.[property];
        const value = schema?.[property];

        if (inheritanceDisabled) {
          return;
        }

        /*
          Check if the value is saved on the current schema
          The value is not a saved one if:
            - savedValues is undefined
            - the property is either "inherit" or "type" and the savedValue does not differentiate from the inherited one
            - the property is either "inherit" or "type" and the savedValue is different to the current value
        */
        const typeOrInheritChanged =
          (property === "inherit" || property === "type") &&
          (savedValue === inheritedValue || savedValue !== value);

        const canBeRemoved = savedValue === undefined || typeOrInheritChanged;

        //only delete a property if the current value and the inherited one are equal
        //and it was not previously saved
        if (
          (canBeRemoved || inheritAttributeChanged) &&
          this.$isEqual(inheritedValue, value)
        ) {
          this.$delete(diffSchema, property);
        }
      });
    },

    merge(source, target) {
      //merge source into target object
      if (this.$isEqual(source, target)) return target;
      if (source === undefined) return target;
      if (
        target != undefined &&
        this.$getType(source) === "object" &&
        this.$getType(target) === "object"
      ) {
        for (let p in source) {
          target[p] = this.merge(source[p], target[p]);
        }
      } else {
        //Use overlay merge for primitive types
        target = source;
      }

      return target;
    },

    async addPersonalization() {
      const personalizations = this.personalizations;
      const id = await this.$prompt("New personalization", {
        rules: [
          (val) => !!val || "Name is required",
          (val) =>
            !personalizations.some((person) => person === val) ||
            "There is already a personalization with id " + val,
        ],
      });

      if (!id) return;
      //create new personalization and add it to the tree
      this.updateSchema(id, { type: "string", inherit: true });
      this.buildPersonUIModels();
      this.buildTree();
      this.$nextTick(() => this.active.push(id));
    },

    existsOnlyInSchema(personalizationId) {
      //check if the property does only exist in the current
      //schema and is not inherited
      return !!(
        this.schema?.properties?.[personalizationId] &&
        !this.isInherited(personalizationId) &&
        !this.presets?.[personalizationId]
      );
    },

    isInherited(personalizationId) {
      return (
        this.inheritProperties &&
        !!this.inheritedSchema?.properties?.[personalizationId] &&
        !this.excludedProperties.includes(personalizationId)
      );
    },

    isRemoved(personalizationId) {
      return (
        !this.isInherited(personalizationId) &&
        !this.presets?.[personalizationId] &&
        !this.schema?.properties?.[personalizationId]
      );
    },

    isPreset(personalizationId) {
      return (
        !!this.presets?.[personalizationId] &&
        !this.isGlobalPersonalization(personalizationId)
      );
    },

    isGlobalPersonalization(personalizationId) {
      return !!this.globalPersonalizations?.[personalizationId];
    },

    getPersonalizationName(personalization) {
      const id = personalization.id;
      let name = personalization.name;
      if (personalization.isCategory) {
        return name;
      }
      const attributes = [];
      if (this.isGlobalPersonalization(id)) {
        attributes.push("fixed");
      } else if (this.isPreset(id)) {
        attributes.push("preset");
      }

      if (this.isInherited(id)) {
        attributes.push("inherited");
      }

      if (this.isRemoved(id)) {
        attributes.push("removed");
      }

      if (attributes.length > 0) {
        return name + " (" + attributes.join(", ") + ")";
      }

      return name;
    },

    isTypeDifferent(schema1, schema2) {
      const type1 = schema1?.type;
      const type2 = schema2?.type;
      return type1 && type2 && type2 !== type1;
    },

    async checkSchemaType(property) {
      const inherited = this.inheritedSchema?.properties?.[property];
      const existing = this.schema?.properties?.[property];
      const typeDiffers = this.isTypeDifferent(existing, inherited);
      if (typeDiffers) {
        const confirmed = await this.$confirm(
          "Type conflict",
          `<p>The inherited type (<strong>` +
            inherited.type +
            `</strong>) of property <strong>` +
            property +
            `</strong> differs from the existing type (<strong>` +
            existing.type +
            `</strong>). The current schema will be overriden.</p> <p>Do you want to proceed?</p>`
        );

        return confirmed;
      }
      return true;
    },

    getCategory(personalizationId) {
      let schema;
      if (this.isGlobalPersonalization(personalizationId)) {
        schema = this.globalPersonalizations[personalizationId];
      } else if (this.isPreset(personalizationId)) {
        schema = this.presets[personalizationId];
      } else {
        schema = this.models?.[personalizationId]?.schema;
      }
      const category = schema?.schemaUI?.editorParameters?.category;
      return category;
    },

    schemaRangeChangesAllowed(personalizationId) {
      return (
        this.canChangePresetPersonAttributes &&
        !this.isGlobalPersonalization(personalizationId)
      );
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    inheritProperties() {
      return this.schema.inheritProperties;
    },

    excludedProperties() {
      return this.schema?.inheritExcludePropertyInformation ?? [];
    },

    personalizationPath() {
      const path = this.uimodel.path ?? "";
      const pathParts = path.split("/").filter((part) => part !== "$");
      return "properties." + pathParts.join(".properties.");
    },

    personalizations() {
      let personalizations = [];
      if (this.schema?.properties)
        personalizations = Object.keys(this.schema?.properties);

      if (this.inheritedSchema?.properties) {
        Object.keys(this.inheritedSchema?.properties).forEach((property) => {
          const inheritActivated =
            this.inheritProperties &&
            !this.excludedProperties.includes(property);

          const personExists = personalizations.some(
            (person) => person === property
          );
          if (inheritActivated && !personExists) {
            personalizations.push(property);
          }
        });
      }

      if (this.presets) {
        Object.keys(this.presets).forEach((preset) => {
          if (personalizations.some((person) => person === preset)) return;
          personalizations.push(preset);
        });
      }

      if (this.globalPersonalizations) {
        Object.keys(this.globalPersonalizations).forEach((globalPerson) => {
          if (personalizations.some((person) => person === globalPerson))
            return;
          personalizations.push(globalPerson);
        });
      }

      const getPersonSortNumber = (p) => {
        /*  Personalizations should be sorted first by category (1. name, 2. address, 3. other categories, 4. remaining personalization) 
        and then alphabetically */
        const category = this.getCategory(p);
        if (category?.id === "name") return 1;
        if (category?.id === "address") return 2;
        if (category?.id) return 3;
        return 4;
      };

      //sort the personalizations so that the active ones are above
      //the others.
      return personalizations.sort((p1, p2) => {
        const number1 = getPersonSortNumber(p1);
        const number2 = getPersonSortNumber(p2);
        if (number1 !== number2) {
          return number1 - number2;
        }
        return p1.localeCompare(p2);
      });
    },

    description() {
      return this.schema?.description?.values?.["en-GB"];
    },

    allowedSchemaTypes() {
      return ["string", "number", "integer", "boolean"];
    },

    personalizationsForInheritance() {
      return this.personalizations.filter((property) => {
        //if configuration flag changePresetPersonAttributes is set to false
        //remove all presets and global personalizations from the list
        return (
          this.canChangePresetPersonAttributes === true ||
          (!this.isPreset(property) && !this.isGlobalPersonalization(property))
        );
      });
    },
  },
};
</script>

<style scoped>
.personalization-editor > .loading-overlay {
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  background-color: transparent;
}

.personalization-editor > .loading-overlay::v-deep .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.personalization-editor
  > .loading-overlay::v-deep
  .v-overlay--active
  .v-overlay__scrim {
  opacity: 0 !important;
}

/* NO-TABLE STYLES*/
.personalization {
  display: flex;
  flex-flow: column;
}

.personalization:not(.multiple-presets),
.personalization > .presets-container > .preset {
  display: flex;
  flex-flow: row;
}

.personalization > .presets-container {
  width: 100%;
}

.personalization.multiple-presets > .presets-container > .preset {
  margin-left: 100px;
}

.personalization > .presets-container > .preset > .schema-editor {
  margin-left: 0;
}

.personalization > .presets-container > .preset > .schema-editor.show-json {
  margin-left: 20px;
}

.personalizations-tree::v-deep
  .v-treeview-node--disabled.v-treeview-node--selected
  .v-treeview-node__checkbox {
  color: rgba(255, 140, 0, 0.38) !important;
}

.personalizations-tree::v-deep .v-treeview-node__root {
  align-items: flex-start;
}

.personalizations-tree::v-deep .v-treeview-node__content {
  display: flex;
  align-items: center;
}

.personalizations-tree::v-deep
  .v-treeview-node__content
  > .v-treeview-node__label
  > .schema-editor {
  margin: 6px 0 18px 0;
  width: calc(100% - 6px);
  padding: 0;
  color: rgba(0, 0, 0, 0.87) !important;
}

.personalizations-tree::v-deep
  .v-treeview-node__content
  > .v-treeview-node__label
  > div
  > .personalization-name {
  color: rgba(0, 0, 0, 0.87);
  margin-right: 6px;
}

.personalizations-tree::v-deep
  .v-treeview-node__content
  > .v-treeview-node__label
  > div
  > .personalization-name.inherited {
  color: darkorange;
}

.personalizations-tree::v-deep
  .v-treeview-node__content
  > .v-treeview-node__label
  > div
  > .personalization-name.preset:not(.inherited) {
  color: darkgreen;
}

.personalizations-tree::v-deep
  .v-treeview-node__content
  > .v-treeview-node__label
  > div
  > .personalization-name.global:not(.inherited) {
  color: blue;
}

.personalizations-tree::v-deep
  .v-treeview-node__content
  > .v-treeview-node__label
  > div
  > .personalization-name.removed {
  color: red;
}
</style>